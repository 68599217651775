<template>
  <div class="field">
    <div class="title-btn-container">
      <NeoTitle :text="$t('coConfig.documentType')" />
      <a-button type="primary" size="small">{{ $t('coConfig.addNewDocumentType') }}</a-button>
    </div>
    <template>
      <a-table :columns="columns" :data-source="serviceType" bordered :showHeader="false" :pagination="false">
        <template v-for="col in ['detail']" :slot="col" slot-scope="text, record">
          <div :key="col">
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="(e) => handleChange(e.target.value, record.key, col)"
            />
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>
        <template slot="operation" slot-scope="text, record, index">
          <div class="editable-row-operations">
            <span v-if="record.editable">
              <a @click="() => save(record.key, index)">{{ $t('save') }}</a>
              <a-popconfirm title="Sure to cancel?" @confirm="() => cancel(record.key)">
                <a>{{ $t('cancel') }}</a>
              </a-popconfirm>
            </span>
            <span v-else>
              <a :disabled="editingKey !== ''" @click="() => edit(record.key)">{{ $t('edit') }}</a>
            </span>
            <a-popconfirm
              v-if="serviceType.length && !record.editable"
              title="Sure to delete?"
              @confirm="() => onDelete(record.key)"
            >
              <a href="javascript:;">{{ $t('delete') }}</a>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
    </template>
  </div>
</template>

<script>
export default {
  name: 'DoucmentTypes',
  props: {
    tabledata: {
      type: Array,
    },
  },
  data() {
    const serviceType = this.tabledata
    this.cacheData = serviceType.map((item) => ({ ...item }))
    return {
      serviceType,
      editingKey: '',
      columns: [
        {
          title: '文档类型',
          dataIndex: 'st',
          width: '20%',
          scopedSlots: { customRender: 'st' },
          customRender: (text, row, index) => {
            const obj = {
              children: text,
              attrs: {},
            }
            if (index === 0) {
              // 第一行数据开始，跨行合并的长度为数据data的长度
              obj.attrs.rowSpan = this.serviceType.length
            }
            if (index >= 1) {
              // 从第一行往后的所有行表格均合并
              obj.attrs.rowSpan = 0
            }
            return obj
          },
        },
        {
          title: 'detail',
          dataIndex: 'detail',
          width: '50%',
          scopedSlots: { customRender: 'detail' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
    }
  },
  methods: {
    handleChange(value, key, column) {
      const newData = [...this.serviceType]
      const target = newData.filter((item) => key === item.key)[0]
      if (target) {
        target[column] = value
        this.serviceType = newData
      }
    },
    edit(key) {
      const newData = [...this.serviceType]
      const target = newData.filter((item) => key === item.key)[0]
      this.editingKey = key
      if (target) {
        target.editable = true
        this.serviceType = newData
      }
    },
    save(key, index) {
      const newData = [...this.serviceType]
      const newCacheData = [...this.cacheData]
      console.log(newCacheData, index, key)
      const target = newData.filter((item) => key === item.key)[0]
      const targetCache = newCacheData.filter((item) => key === item.key)[0]
      if (target && targetCache) {
        delete target.editable
        this.serviceType = newData
        Object.assign(targetCache, target)
        this.cacheData = newCacheData
      }
      this.editingKey = ''
    },
    cancel(key) {
      const newData = [...this.serviceType]
      const target = newData.filter((item) => key === item.key)[0]
      this.editingKey = ''
      if (target) {
        Object.assign(target, this.cacheData.filter((item) => key === item.key)[0])
        delete target.editable
        this.serviceType = newData
      }
    },
    onDelete(key) {
      const dataSource = [...this.serviceType]
      this.serviceType = dataSource.filter((item) => item.key !== key)
    },
  },
}
</script>

<style lang="less" scoped>
.field {
  .editable-row-operations a {
    margin-right: 15px;
  }
  .title-btn-container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 15px;
    font-weight: 700;
  }
  /deep/.ant-table-tbody {
    text-align: center;
    & tr:nth-child(1) {
      & td:nth-child(1) {
        color: #8895ab;
        background-color: rgba(222, 232, 243, 0.25);
      }
    }
  }
}
</style>
